import React, {useEffect, useRef} from 'react';
import { navigate } from "gatsby";
import { gsap } from "gsap";

import { getPicto, getName } from "../shared/category";

import {
    flattenConfs,
    getCurrentTime,
    printDatas,
    getAutoplaySpeed,
    fadeIn,
    fadeOut,
    getOngoing, getIncoming, getDayConfs, getMorningConfs, getAfternoonConfs, isConfPassed
} from "../../helpers/helpers";

import Layout from "../layout/layout"
import Seo from "../layout/seo"
import Time from "../../images/time_o.svg";


const Column = (props) => {
    const { day, currentDate, list, label } = props;

    return (
        <div className={`flex-1 p-5 h-full cal-column`}>
            <span className="inline-block bg-borange rounded-7 text-white text-5.75" style={{ padding: "7px 21px 8px" }}>{ label }</span>

            <ol className="mt-10 space-y-8">
                { list.map((conf, index) => {
                    const hours = `${conf.startHour}:${conf.startMinutes} - ${conf.endHour}:${conf.endMinutes}`;
                    const isPassed = isConfPassed(day, conf, currentDate);

                    return (
                        <li key={index} className={isPassed ? 'opacity-40' : ''}>
                            <div className={"flex flex-row items-center justify-between"}>
                                <div className="flex-1 flex flex-row items-center">
                                    <img className="w-6 mb-1 mr-3" src={getPicto(conf.description)} alt={getName(conf.description)}/>
                                    <span className="font-bryant leading-none font-medium uppercase text-xl tracking-cat">{getName(conf.description)}</span>
                                </div>

                                <div className="flex-none ml-3 mb-1">
                                    <span className={"inline-flex flex-row items-center justify-between text-xl text-borange"}>
                                        <img className={"flex-none mr-2 mt-1"} src={Time} alt="Horaires"/> <span className={"flex-1"}>{hours}</span>
                                    </span>
                                </div>
                            </div>

                            <h3 className={"text-5.5 leading-5 font-medium tracking-0.075"} dangerouslySetInnerHTML={{__html: conf.title}}/>
                        </li>
                    )
                })}
            </ol>
        </div>
    )
}


const Index = (props) => {

    const { pageContext: { data: pageContextData }, location: { search } } = props;

    let delayedCalls = null;
    let currentDate = getCurrentTime(search);
    const mainRef = useRef();
    const conferencesDays = pageContextData;
    const allConfs = flattenConfs(conferencesDays);

    let ongoing = getOngoing(allConfs, currentDate);
    let incoming = getIncoming(allConfs, currentDate);

    const dayConfs = getDayConfs(conferencesDays, currentDate);
    let morningConfs = null;
    let afternoonConfs = null;

    if (dayConfs) {
        morningConfs = getMorningConfs(dayConfs.confs);
        afternoonConfs = getAfternoonConfs(dayConfs.confs);
    }


    const repeat = (iterations, onComplete) => {
        if (iterations > 0) {
            delayedCalls = gsap.delayedCall(getAutoplaySpeed(search, 15), () => {
                repeat(iterations - 1, onComplete);
            });
        } else {
            onComplete();
        }
    }

    const fallBackLoop = () => {
        const onComplete = () => fadeOut(mainRef.current, () => window.location.reload());
        repeat(19, onComplete);
    }
    const loopSelf = () => {
        delayedCalls = gsap.delayedCall(getAutoplaySpeed(search, 30), loopHandler);
    }
    const loopOnce = () => {
        delayedCalls = gsap.delayedCall(getAutoplaySpeed(search, 30), () => {
            fadeOut(mainRef.current, () => navigate(`/${search}`));
        });
    }
    const loopHandler = () => {
        currentDate = getCurrentTime(search);
        ongoing = getOngoing(allConfs, currentDate);
        incoming = getIncoming(allConfs, currentDate);

        if (process.env.GATSBY_IS_FALLBACK === "true") {
            fallBackLoop();
        } else {
            if (ongoing || incoming?.pause || !incoming) {
                loopOnce();
            } else {
                loopSelf();
            }
        }
    }

    useEffect(() => {
        printDatas(search, conferencesDays, currentDate);
        fadeIn(mainRef.current, loopHandler);

        return () => { delayedCalls.kill(); }
    }, []);


    const showMornings = morningConfs && (dayConfs.split || (!dayConfs.split && incoming?.morning));
    const showAfternoons = afternoonConfs && (dayConfs.split || (!dayConfs.split && !incoming?.morning));

    return (
        <Layout>
            <Seo title="Calendrier Vivatech"/>

            { typeof window !== "undefined" && (
                <div className={'h-full'} ref={mainRef} style={{ opacity: 0, visibility: "hidden" }}>
                    <div className="flex flex-row h-full">
                        { showMornings && <Column day={dayConfs} currentDate={currentDate} list={morningConfs} label={"Ce matin"}/> }
                        { showAfternoons && <Column day={dayConfs} currentDate={currentDate} list={afternoonConfs} label={"Cet après-midi"}/> }
                    </div>
                </div>
            )}
        </Layout>
    )
}

export default Index
